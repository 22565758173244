import React from 'react'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import { NextPage, GetStaticProps } from 'next'

const MyCustom404: NextPage<{}> & { hasStaticProps: boolean } = () => {
    // Opinionated: do not record an exception in Sentry for 404
    return <ErrorPage statusCode={404} />
}

MyCustom404.hasStaticProps = true

export const getStaticProps: GetStaticProps<{}> = async () => {
    return { props: {} }
}

export default MyCustom404
